import React, { useEffect, useContext } from 'react';
import { Container, Pagination } from 'semantic-ui-react';
import AuthContext from '../context/authContext';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { mobileW } from '../myfunctions';


function ShowPagination({ myTab, setMyTab, rowCurrent, darkOn, darkForce }) {
    const authContext = useContext(AuthContext);
    const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
    useEffect(() => {

        setMyTab({ ...myTab, current: myTab.data.slice(0, rowCurrent) })

        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const onChange = (e, pageInfo) => {

        if (Number(pageInfo.activePage) === 1) {
            setMyTab({ ...myTab, current: myTab.data.slice(0, rowCurrent), activePage: 1 })
        } else {
            setMyTab({ ...myTab, current: myTab.data.slice((pageInfo.activePage - 1) * rowCurrent, pageInfo.activePage * rowCurrent), activePage: pageInfo.activePage })
        }
    };

    return (
        myTab && myTab.data && myTab.data.length > rowCurrent ?
            <Container textAlign='center' style={{ marginTop: 20, marginBottom: 10 }}>
                {width < mobileW() ?
                    <Pagination style={{ marginTop: 10 }}
                        inverted={darkOn ? darkForce ? darkForce : authContext.darkMode : false}
                        firstItem={null}
                        lastItem={null}
                        boundaryRange={0}
                        siblingRange={1}
                        activePage={myTab.activePage}
                        onPageChange={onChange}
                        totalPages={Math.ceil(myTab.data.length / rowCurrent)}
                        ellipsisItem={null} />
                    :
                    <Pagination style={{ marginTop: 10 }}
                        inverted={darkOn ? darkForce ? darkForce : authContext.darkMode : false}
                        siblingRange={1}
                        activePage={myTab.activePage}
                        onPageChange={onChange}
                        totalPages={Math.ceil(myTab.data.length / rowCurrent)}
                        ellipsisItem={null} />
                }
            </Container>
            : ""
    )
}

export default ShowPagination;