import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function MyImageLazy(image) {

  const [isOpen, setisOpen] = useState(false)

  return (

    <>
      {isOpen && image.openView ? <Lightbox
        mainSrc={image.src}
        onCloseRequest={() => setisOpen(false)}

      /> : ""}
      {
        image.className ?
          <LazyLoadImage
            alt={image.alt}
            height={image.height}
            src={image.src} // use normal <img> attributes as props
            //width={image.width}
            onClick={() => setisOpen(true)}
            effect="blur" className={image.openView ? `cursorPointer ${image.className}` : image.className} />
          :
          <LazyLoadImage
            alt={image.alt}
            style={{ maxHeight: image.maxHeight, width: "100%" }}
            height={image.height}
            src={image.src} // use normal <img> attributes as props
            //width={image.width}
            className={image.openView ? 'cursorPointer' : ''}
            onClick={() => setisOpen(true)}
            effect="blur" />
      }
    </>


  )
}

export default MyImageLazy;