import React, { useEffect, useContext, useState } from 'react';
import { Button, Form, Grid, Header, Icon, Image, Input, Label, Message, Modal, Segment, Table } from 'semantic-ui-react';
import AuthContext from '../context/authContext';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { getlinkplayerprofile, noSubIsActive, removeEmojis, removeSpecialChars, txtCase } from '../myfunctions';
import { ConnectWS } from '../ConnectWS';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import ShowImgAvatar from './ShowImgAvatar';
import { confirmAlert } from 'react-confirm-alert';
import LogoTeamLigaGenerator from '../assets/logotgver1.svg';
import useCheckUserIsDemo from '../hooks/useCheckUserIsDemo';

function MembersInTeams(props) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  const [open, setOpen] = useState(false)
  const [openData, setopenData] = useState(null)

  const [isUpdate, setisUpdate] = useState(0)
  const { t } = useTranslation();
  const isDemo = useCheckUserIsDemo()


  const [isAS, setisAS] = useState(false)
  function checkSubIsActiv() {
    try {
      if(isDemo){
        setisAS(true)
      }else if (authContext.user.subscription_info && authContext.user.subscription_info.subscription_extended >= 0 && authContext.user.subscription_info.subscription_active) {
        setisAS(true)
      } else {
        setisAS(false)
      }
    } catch (e) {
      setisAS(false)
    }
  }

  useEffect(() => {
    checkSubIsActiv()
    setOpen(props.openModalMembersTeams.open)
    setisUpdate(0)

    if (props.openModalMembersTeams.open) {
      getData(props.openModalMembersTeams.data.subtournament_id)
    }
    return () => {
    }; // use effect cleanup to set flag false, if unmounted
  }, [props.openModalMembersTeams])// eslint-disable-line react-hooks/exhaustive-deps

  function exitModal() {
    if (isUpdate) {
      authContext.changeRefreshView(authContext.refreshView + 1)
    }

    props.setopenModalMembersTeams({ open: false, data: null })
    setOpen(false)
  }


  function getData(subtournament_id) {
    ConnectWS("/generator/getMembersInSubtournamentTeams", authContext.token, { subtournament_id: subtournament_id }, function (response, logout) {

      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {
        //
        let arrtmp = [];
        arrtmp.length = 0;
        if (response.data.length > 0) {
          response.data.forEach((data, i) => {
            if (data.team_id === props.openModalMembersTeams.data.row.member_id && data.members.length > 0) {
              arrtmp.push(...data.members)
            }

          })
        }

        if (arrtmp.length > 0) {
          setopenData(arrtmp)
        } else {
          setopenData(null)
        }

      } else {
        setopenData(null)
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }

    });
  }


  const [formAddUsers, setFormAddUsers] = useState({
    name: "",
    surname: "",
    email: ""
  });
  const [loading, setloading] = useState(false);

  const addDBUsersToTournament = e => {
    e.preventDefault();

    setloading(true)
    let nametmp = txtCase(formAddUsers.name.trim()) + " " + txtCase(formAddUsers.surname.trim())

    let team_id = props.openModalMembersTeams.data.row.member_id;
    let subtournament_id = props.openModalMembersTeams.data.subtournament_id


    ConnectWS("/generator/addMemberToSubtournamentTeam", authContext.token, { team_id: team_id, subtournament_id: subtournament_id, name: nametmp, email: formAddUsers.email }, function (response, logout) {

      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {
        setFormAddUsers({
          name: "",
          surname: "",
          email: "",
        })
        addToast(response.data.message, { appearance: 'success' });
        getData(props.openModalMembersTeams.data.subtournament_id)
        setisUpdate(1)
        // getData()
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }
      setloading(false)


    });

  }

  function removeMember(row) {
    confirmAlert({
      title: 'Proszę potwierdzić',
      message: 'Czy chcesz skasować uczestnika ' + row.member_name + '?',
      buttons: [
        {
          label: 'Tak',
          onClick: () => removeMemberAcc(row)
        },
        {
          label: 'Nie',
          onClick: () => ''
        }
      ]
    });

    function removeMemberAcc(row) {
      let subtournament_id = props.openModalMembersTeams.data.subtournament_id
      ConnectWS("/generator/removeMemberFromSubtournamentTeam", authContext.token, { subtournament_id: subtournament_id, ...row }, function (response, logout) {

        if (logout === 1) {

        } else if (response && response.status && Number(response.status) === 200) {
          addToast(response.data.message, { appearance: 'success' });
          getData(props.openModalMembersTeams.data.subtournament_id)
          setisUpdate(1)
        } else {

          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }

        }

      });
    }
  }

  return (
    <>
      <Modal
        onClose={() => exitModal()}
        open={open}
        centered={width < 900 ? false : true}
        closeIcon
        size='large'


      >
        <Modal.Header>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={12} verticalAlign="middle">
                <Header as='h2' textAlign='left'>
                  <Icon name='users' color="red" />
                  <Header.Content>Lista uczestników
                    <Header.Subheader>
                      Drużyna: {open ? props.openModalMembersTeams.data.row.name : ""}
                    </Header.Subheader>
                  </Header.Content>

                </Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right" >
                <Image alt="logo" floated='right' size='big' centered src={LogoTeamLigaGenerator} />
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </Modal.Header>
        <Modal.Content style={{ backgroundColor: "#e0e1e2" }} >
          <Modal.Description >
            <Grid divided style={{ marginTop: 5 }}>
              <Grid.Row columns={1}>

                <Grid.Column>
                  <Table singleLine size='small' celled striped compact unstackable >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={2} textAlign='center'>LP</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center' style={{ lineHeight: "12px" }}>ZAWODNIK</Table.HeaderCell>
                        <Table.HeaderCell width={2} textAlign='center'></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {openData ? openData.map((row, i) =>
                        <Table.Row key={i}   >
                          <Table.Cell textAlign='center'>{i + 1}</Table.Cell>
                          <Table.Cell><figure className='imgPlayer'><ShowImgAvatar urlpic={row.member_img} mtype={"0"} msize={"mini"} /><figcaption>{getlinkplayerprofile(null, row.member_name, 0, 14)}</figcaption></figure></Table.Cell>
                          <Table.Cell textAlign='center'><Icon name="trash" className='cursorPointer' onClick={() => removeMember(row)} title="KASUJ UCZESTNIKA" /></Table.Cell>
                        </Table.Row>
                      ) :
                        <Table.Row >
                          <Table.Cell colspan={3}>
                            <Message>Brak przypisanych uczestników do tej drużyny</Message>
                          </Table.Cell>
                        </Table.Row>}

                    </Table.Body>
                  </Table>
                  <Segment style={{ backgroundColor: "#f0f0f0" }}>
                    <Label color='blue' ribbon style={{ marginBottom: 10 }}>
                      <Icon name="user" /> Dodaj zawodnika
                    </Label>

                    {isAS ?
                      <Form size='tiny' onSubmit={addDBUsersToTournament}>

                        <Form.Field
                          control={Input}
                          value={formAddUsers.name}
                          onChange={(event, data) => setFormAddUsers({ ...formAddUsers, name: removeSpecialChars(removeEmojis(data.value)) })}
                          label={t("WidokTurnieju.zapisy_imie")}
                          required={true}
                          placeholder={t("WidokTurnieju.zapisy_imie")}


                        />
                        <Form.Field
                          control={Input}
                          value={formAddUsers.surname}
                          onChange={(event, data) => setFormAddUsers({ ...formAddUsers, surname: removeSpecialChars(removeEmojis(data.value)) })}
                          required={true}
                          label={t("WidokTurnieju.zapisy_nazwisko")}
                          placeholder={t("WidokTurnieju.zapisy_nazwisko")}


                        />

                        <Form.Field
                          control={Input}
                          value={formAddUsers.email}
                          onChange={(event, data) => setFormAddUsers({ ...formAddUsers, email: data.value })}
                          label={t("WidokTurnieju.zapisy_email")}

                          placeholder={t("WidokTurnieju.zapisy_email")}
                          type='email'

                        />

                        <Button loading={loading} disabled={loading} color='blue' size='small' fluid type='submit'>DODAJ ZAWODNIKA</Button>
                      </Form>
                      : noSubIsActive()}

                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>



          <Button size="tiny" labelPosition='right' icon='close' color='grey' onClick={() => exitModal()} content="WYJDŹ" />
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default MembersInTeams;