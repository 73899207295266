import React, { useState, useEffect, useContext } from 'react';
import { Icon, Header, Divider,Form,Input,Button } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';
import { useToasts } from 'react-toast-notifications';
import useAuth from '../../../../hooks/useAuth';
import { ConnectWS } from '../../../../ConnectWS'
import useCheckUserIsDemo from '../../../../hooks/useCheckUserIsDemo';
import { changeInitialScale } from '../../../../myfunctions';

function ChangePassword(props) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const [loading, setloading] = useState(false);
  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars

  const isDemo = useCheckUserIsDemo()

  const [form, setForm] = useState({
    old_password: '',
    new_password: '',
    new_password_repeat: '',
  });
  const [formError, setFormError] = useState(false);
  useEffect(() => {
    changeInitialScale(0.5)
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log("View: ChangePassword")
  }
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  const submitModal = e => {
    e.preventDefault();



    let old_password = form.old_password
    let new_password = form.new_password
    let new_password_repeat = form.new_password_repeat

    if(new_password_repeat!==new_password){
      addToast('Wpisane hasła sa różne', { appearance: 'warning' });
      setFormError(true)
      return
    }
    setFormError(false)

    setloading(true)

    ConnectWS("login/changePassword", authContext.token, { user_id: authContext.user.userId, old_password: old_password, new_password: new_password }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {

        addToast(response.data.message, { appearance: 'success' });
      } else {
        if(logout===101){
          addToast(response, { appearance: 'info' });
        }else if(logout===102){
          addToast(response, { appearance: 'warning' });
        }else{
          addToast(response, { appearance: 'error' });
        }

      }
      setloading(false)


    });


  }

  return (
    <> <Header as='h5' textAlign='center' block>
      <Icon name='unlock' />
      <Header.Content>Zmiana Hasła</Header.Content>
    </Header>
      <Divider />
      <Form onSubmit={submitModal} size='tiny'>
        <Form.Field
          control={Input}
          type="password"
          required
          label='Stare Hasło'
          placeholder='Stare Hasło'
          value={form.old_password}
          onChange={(e, data) => setForm({ ...form, old_password: data.value })}
        />
        <Form.Field
          control={Input}
          type="password"
          required
          error={formError}
          label='Nowe Hasło'
          placeholder='Nowe Hasło'
          value={form.new_password}
          onChange={(e, data) => setForm({ ...form, new_password: data.value })}
        />
        <Form.Field
          control={Input}
          type="password"
          required
          error={formError}
          label='Powtórz nowe Hasło'
          placeholder='Powtórz nowe Hasło'
          value={form.new_password_repeat}
          onChange={(e, data) => setForm({ ...form, new_password_repeat: data.value })}
        />


        <Button size='tiny' color='google plus' fluid loading={loading ? true : false} disabled={loading ? true : false || isDemo ? true : false } >ZMIANA HASŁA</Button>
      </Form>
    </>
  )
}

export default ChangePassword;