import React, { useEffect, useContext, useState } from 'react';
import { Grid, Select, Icon } from 'semantic-ui-react'

import { ConnectWS } from '../../../../ConnectWS';
import AuthContext from '../../../../context/authContext';


function FinalTableCalcPointsSystems(propsRoot) {
  const [final_table_calc_points_system_idS, setfinal_table_calc_points_system_idS] = useState([]);
  const authContext = useContext(AuthContext);


  useEffect(() => {

    getFinalTableCalcPointsSystems(propsRoot.subtournament_id)
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [propsRoot.subtournament_id])// eslint-disable-line react-hooks/exhaustive-deps

  function getFinalTableCalcPointsSystems(subtournament_id) {
    if (subtournament_id && Number(subtournament_id) > 1) {
      ConnectWS("/generator/getFinalTableCalcPointsSystems", authContext.token, { subtournament_id: subtournament_id }, function (response, logout) {
        if (logout === 1) {


        } else if (response && response.status && Number(response.status) === 200) {
          let tmparr = []

          if (response.data.user_systems) {
            response.data.user_systems.forEach((row) => {
              tmparr.push({ key: row.system_name, text: row.system_name, value: row.final_table_calc_points_system_id === null ? 0 : row.final_table_calc_points_system_id })
            })
          }
          if (response.data.subtournament_system && response.data.subtournament_system.final_table_calc_points_system_id) {
            propsRoot.setfinal_table_calc_points_system_id(response.data.subtournament_system.final_table_calc_points_system_id)
          }
          setfinal_table_calc_points_system_idS(tmparr)

        } else {
          if (logout === 101) {
            //    addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            //    addToast(response, { appearance: 'warning' });
          } else {
            //     addToast(response, { appearance: 'error' });
          }
          //console.log(response);
        }

      });
    } else {
      console.log("brak subtournament_id")
    }
  }

  return (
    final_table_calc_points_system_idS && final_table_calc_points_system_idS.length > 1 ?
      <Grid.Row className='mp3 hiddenPrint'>
        <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Algorytm punktacji</b> <Icon name="setting" color='orange' /><br /><span style={{ fontSize: 11 }}>(tabela końcowa - kolumna PUNKTACJA Z TURNIEJU)</span></Grid.Column>
        <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'>
          <Select
            fluid
            label='Algorytm punktacji'
            options={final_table_calc_points_system_idS}
            value={propsRoot.final_table_calc_points_system_id}
            onChange={(e, d) => propsRoot.setfinal_table_calc_points_system_id(d.value)}
            placeholder='Algorytm punktacji'
          />
        </Grid.Column>
      </Grid.Row>
      : ""
  )
}

export default FinalTableCalcPointsSystems;