import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Icon, Input } from 'semantic-ui-react';



function AGGridActivity(props) {
    const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
    const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars


    useEffect(() => {

        setGridApi(props.gridApi)
        setGridColumnApi(props.gridColumnApi)


        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, [props.gridApi, props.gridColumnApi]);// eslint-disable-line react-hooks/exhaustive-deps




    const onBtnExport = useCallback((gridApi) => {
        gridApi.exportDataAsCsv({
            skipColumnGroupHeaders: false,
            skipColumnHeaders: false,
        });
    }, []);


    function quickSearch(val, appGrid) {
        if (val.length > 1) {
            appGrid.setQuickFilter(val);
        } else {
            appGrid.setQuickFilter(null);
        }

    }

    /*
    const sizeToFit = useCallback((appGrid) => {
        appGrid.sizeColumnsToFit();
    }, []);

    const autoSizeAll = useCallback((skipHeader, gridApiRef) => {
        const allColumnIds = [];
        gridApiRef.current.columnApi.getAllColumns().forEach((column) => {
            allColumnIds.push(column.getId());
          });
          if (skipHeader === false) {
            gridApiRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
          } else {
            gridApiRef.current.api.sizeColumnsToFit();
          }
    }, []);// eslint-disable-line 

*/


    return (
        <Grid style={{ margin: 0,padding:0, marginTop:10,marginBottom:5 }}>
            <Grid.Row style={{ margin: 0,padding:0}}>
                <Grid.Column width={9} style={{ margin: 0,padding:0}}>
                    {props.quickSearchHidden === true ? "" :
                    <Input placeholder="Szukaj" size='mini' style={{width:250}} onChange={(e) => quickSearch(e.target.value, gridApi)} />
                       }
                </Grid.Column>
                <Grid.Column width={7} textAlign="right" style={{ margin: 0,padding:0}}>
{/* 
                    <Icon bordered name="chevron left" className='cursorPointer iconbutton' onClick={() => autoSizeAll(false, props.gridApiRef)} />
                    <Icon bordered name="chevron right" className='cursorPointer iconbutton' onClick={() => sizeToFit(gridApi)} />
                    */}
                    <Icon bordered name="download" title="POBIERZ PLIK CSV" className='cursorPointer iconbutton' onClick={() => onBtnExport(gridApi)} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default AGGridActivity;