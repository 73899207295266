import React, { useEffect, useRef } from 'react';



import JoditEditor from 'jodit-react';

function GetEditorTG(props) {

    useEffect(() => {

    },[props.rte])// eslint-disable-line react-hooks/exhaustive-deps

    const editor = useRef(null);

   
    return (
        <div style={{marginBottom:25}}>
        <JoditEditor
			ref={editor}
			value={props.rte}
			onChange={newContent => props.setrte(newContent)}
		/>
        </div>
    )
}

export default GetEditorTG;