import React, { useEffect, useState, } from 'react';
import { Button, Dropdown, Icon, Segment, Table, } from 'semantic-ui-react'
import { useToasts } from 'react-toast-notifications';


import useWindowDimensions from '../../../../hooks/useWindowDimensions';

import { ConnectWS } from '../../../../ConnectWS';
import { useContext } from 'react';
import AuthContext from '../../../../context/authContext';
import { confirmAlert } from 'react-confirm-alert';


function BracketLeagueAdvancedModeCreate(propsRoot) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  const [userList, setuserList] = useState([])
  const [loading, setloading] = useState(false)
  const [rowDataTournamentL, setrowDataTournamentL] = useState([])

  function getListUsers(subtournament_id, round_nr, league_revenge_round) {
    ConnectWS("/generator/getUnassignedSubtournamentMembers", authContext.token, { subtournament_id: subtournament_id, round_nr: round_nr, league_revenge_round: league_revenge_round }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });

      } else if (response && response.status && Number(response.status) === 200) {
        //setDataOpen(dataJson)
        let dictemat = []
        dictemat.length = 0
        dictemat.push({ key: -1, text: "PAUZA", value: 0,image: { avatar: true, src: '/img/nouser.png' } })
        response.data.forEach((data, i) => {
          if (data.member_id > 0) {
            dictemat.push({ key: i, text: data.member_name.toUpperCase(), value: data.member_id,image: { avatar: true, src: data.img_file ? data.img_file : '/img/nouser.png' }})
          }

        })
        setuserList(dictemat);
      } else {

      }



    });
  }

  useEffect(() => {

    if (propsRoot.subtournamentId > 0) {
      getListUsers(propsRoot.subtournamentId, 1, 0)
      if(rowDataTournamentL.length===0){
        addRowTab()
      }
    }

    return () => {
    }; // use effect cleanup to set flag false, if unmounted
  }, [propsRoot.subtournamentId])// eslint-disable-line react-hooks/exhaustive-deps


  function addRowTab() {
    let tmp = []
    tmp = [...rowDataTournamentL]
    tmp.push({
      round_nr: tmp.length + 1,
      league_revenge_round: 0,
      matches: [{
        member1_id: null,
        member2_id: null
      }]
    })

    setrowDataTournamentL(tmp)
  }

  function addRowTabMatches(i) {
    let tmp = []
    tmp = [...rowDataTournamentL]
    tmp[i].matches.push({
      member1_id: null,
      member2_id: null
    })

    setrowDataTournamentL(tmp)
  }

  const handleDelete = (index, e) => {
    confirmAlert({
      title: 'Proszę potwierdzić',
      message: 'Czy chcesz skasować kolejkę?',
      buttons: [
        {
          label: 'Tak',
          onClick: () => setrowDataTournamentL(rowDataTournamentL.filter((v, i) => i !== index))
        },
        {
          label: 'Nie',
          onClick: () => ''
        }
      ]
    });

  }


  const handleTypeRound = (index, e) => {
    let tmp = []
    tmp = [...rowDataTournamentL]
    tmp[index].league_revenge_round = e
    setrowDataTournamentL(tmp)
  }

  const handleDeleteRow = (index, j) => {

    confirmAlert({
      title: 'Proszę potwierdzić',
      message: 'Czy chcesz skasować tej pojedynek?',
      buttons: [
        {
          label: 'Tak',
          onClick: () => handleDeleteRowAcc(index, j)
        },
        {
          label: 'Nie',
          onClick: () => ''
        }
      ]
    });

    function handleDeleteRowAcc(index, j) {
      let tmp = []
      tmp = [...rowDataTournamentL]
      tmp[index].matches = tmp[index].matches.filter((v, i) => i !== j)
      setrowDataTournamentL(tmp);
    }


  }

  function changeMemberCell(value, i, j, m) {
    let tmp = []
    tmp = [...rowDataTournamentL]
    if (m === 1) {
      tmp[i].matches[j].member1_id = value
    } else {
      tmp[i].matches[j].member2_id = value
    }
    setrowDataTournamentL(tmp);

  }

  function createLeagueStructCustom() {
    confirmAlert({
      title: 'Proszę potwierdzić',
      message: 'Czy chcesz zatwierdzić obecna strukturę ligi?',
      buttons: [
        {
          label: 'Tak',
          onClick: () => createLeagueStructCustomAcc()
        },
        {
          label: 'Nie',
          onClick: () => ''
        }
      ]
    });

    function createLeagueStructCustomAcc(index, j) {

      if (rowDataTournamentL.length === 0) {
        addToast("Brak wprowadzonej kolejki", { appearance: 'info' });
        return
      }

      let isError = null
      rowDataTournamentL.forEach((row, i) => {
        if (row.matches) {
          row.matches.forEach((row1, j) => {

            if (row1.member1_id === null || row1.member2_id === null) {
              isError = "Brak przypisanych uczestników w kolejce " + (i + 1)
            } else if (row1.member1_id === row1.member2_id) {
              isError = "Jest przypisanych 2 tych samych uczestników w tym samym pojedynku w kolejce " + (i + 1)
            }
          })
        }
      })

      if (isError !== null) {
        addToast(isError, { appearance: 'warning' });
        return
      }


      setloading(true)
      ConnectWS("/generator/createLeagueStructCustom", authContext.token, { subtournament_id: propsRoot.subtournamentId, tournament: rowDataTournamentL }, function (response, logout) {
        if (logout === 1) {
          addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        } else if (response && response.status && Number(response.status) === 200) {
          addToast(response.data.message, { appearance: 'success' });
          authContext.changeRefreshView(authContext.refreshView + 1)
        } else {
          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }
        }
        setloading(false)
      })



    }


  }

  function isErrShow(member1_id, member2_id) {
    if (member1_id === null || member2_id === null || member1_id === member2_id) {
      return true
    }
    return false
  }

  return (
    <div >
      <Button color="orange" size='mini' style={{ marginBottom: 10 }} onClick={() => addRowTab()} icon labelPosition='left'> <Icon name='plus' />DODAJ KOLEJKĘ</Button>
      {rowDataTournamentL.map((row, i) => <div key={i}>
        <Segment className={'CardBlack'} style={{ marginBottom: 10 }}>
          KOLEJKA {row.round_nr} {row.league_revenge_round ? "- rewanżowa" : ""}
          <Button color={row.league_revenge_round ? 'brown' : "blue"} style={{ marginBottom: 10, marginLeft: 10 }} size='mini' onClick={e => handleTypeRound(i, row.league_revenge_round ? 0 : 1)} icon labelPosition='left'> <Icon name='toggle off' /> {row.league_revenge_round ? "OZNACZ JAKO RUNDA ZASADNICZA" : "OZNACZ JAKO RUNDA REWANŻOWA"}</Button>
          <Button color='black' floated='right' style={{ marginBottom: 10 }} size='mini' onClick={e => handleDelete(i, e)} icon labelPosition='right' > <Icon name='trash' /> KASUJ KOLEJKĘ</Button>


          <Table size='small' celled striped compact unstackable >
            <Table.Header>
              <Table.Row>


                <Table.HeaderCell singleLine width={4} textAlign='center'>GOSPODARZ</Table.HeaderCell>
                <Table.HeaderCell singleLine width={4} textAlign='center'>GOŚĆ</Table.HeaderCell>
                <Table.HeaderCell singleLine width={1} textAlign='center'>
                  <Button color="blue" style={{ marginBottom: 10 }} size='mini' onClick={() => addRowTabMatches(i)} icon labelPosition='left'> <Icon name='plus' /> DODAJ POJEDYNEK</Button>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {row.matches && row.matches.length > 0 ?
                row.matches.map((line3, j) =>
                  <Table.Row error={isErrShow(line3.member1_id, line3.member2_id)}>
                    <Table.Cell singleLine width={4} textAlign='center'>
                      {line3.member1_id === null || line3.member1_id === line3.member2_id ? <Icon name='attention' /> : ""}
                      <Dropdown
                        placeholder='Wybierz gospodarza'
                        fluid
                        selection
                        options={userList}
                        value={line3.member1_id}
                        onChange={(e, d) => changeMemberCell(d.value, i, j, 1)}
                      />
                    </Table.Cell>
                    <Table.Cell singleLine width={4} textAlign='center'>
                      {line3.member2_id === null || line3.member1_id === line3.member2_id ? <Icon name='attention' /> : ""}
                      <Dropdown
                        placeholder='Wybierz gościa'
                        fluid
                        selection
                        options={userList}
                        value={line3.member2_id}
                        onChange={(e, d) => changeMemberCell(d.value, i, j, 2)}
                      />
                    </Table.Cell>
                    <Table.Cell singleLine width={1} textAlign='center'>
                      <Button color="grey" style={{ marginBottom: 10 }} size='mini' onClick={e => handleDeleteRow(i, j)} icon labelPosition='right' > <Icon name='trash' />KASUJ POJEDYNEK</Button>
                    </Table.Cell>
                  </Table.Row>
                ) : ""}
            </Table.Body>


          </Table>

        </Segment>
      </div>)}


      <Button color='google plus' loading={loading} disabled={loading} size='mini' fluid style={{ marginTop: 10 }} onClick={() => createLeagueStructCustom()}>ZATWIERDŹ STRUKTURĘ LIGI</Button>

    </div>
  )
}

export default BracketLeagueAdvancedModeCreate;



