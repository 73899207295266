import React, { useEffect, useState, useRef } from 'react';

import { loadingShow } from '../myfunctions';
import { Galleria } from 'primereact/galleria';
import { confirmAlert } from 'react-confirm-alert';
import { Modal, ModalContent } from 'semantic-ui-react';



function GooglePhotosViewer({ url, openView, setGooglePhotosViewerStatus }) {
    const [images, setimages] = useState(null);
    const [loading, setloading] = useState(false);
    const [open, setOpen] = useState(false);
   
    async function showDataPhoto(url) {
        try {
            setloading(true)
            setOpen(true)
            setimages(null)
            const items = [];
            const album = await getPhotosG(url);

            let THUMBNAIL_WIDTH = 200
            let THUMBNAIL_WIDTH2 = 1920
            let THUMBNAIL_HEIGHT = 200
            let THUMBNAIL_HEIGHT2 = 1080
            album.mediaItems.forEach((mediaItem, i) => {
                const item = {
                    title: "Zdjęcie " + i + " z " + album.mediaItems.length,
                    alt: "Zdjęcie " + i + " z " + album.mediaItems.length,
                    width: THUMBNAIL_WIDTH2,
                    itemImageSrc: mediaItem.url.split('=')[0] + `=w${THUMBNAIL_WIDTH2}-h${THUMBNAIL_HEIGHT2}`,
                    thumbnailImageSrc2222: mediaItem.url.split('=')[0] + `=w${THUMBNAIL_WIDTH}-h${THUMBNAIL_HEIGHT}`
                };
                items.push(item);
            });
            setimages(items)
            setloading(false)
            setOpen(false)
            galleria.current.show()
            setTimeout(() => {
                setGooglePhotosViewerStatus({ url: url, openView: false })
            }, 1000);


        } catch (e) {
            console.log(e)
            setGooglePhotosViewerStatus({ url: url, openView: false })
            setloading(false)
            setOpen(false)
            confirmAlert({
                title: 'BŁĄD',
                message: 'Wystąpił problem z pobraniem zdjęć z albumu ' + url,
                buttons: [
                    {
                        label: 'Rozumiem, sprawdzę poprawność linku',
                        onClick: () => ''
                    }
                ]
            });
        }
    }

    async function getPhotosG(url) {
        const payload = {
            method: 'getGooglePhotosAlbum',
            params: {
                sharedLink: url,
                imageWidth: 1920,
                imageHeight: 1080,
                includeThumbnails: true,
                videoQuality: '1080p',
                attachMetadata: false
            },
            id: 1
        };
        return await fetch('https://proxy.turniej-generator.pl', {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'text/plain;charset=UTF-8',
                'Accept': 'application/json'
            }
        }).then(response => response.json()).then(response => response["result"]);
    }


    useEffect(() => {
        if (openView) {
            showDataPhoto(url)

        }

        return () => {

        }; // use effect cleanup to set flag false, if unmounted
    }, [openView])// eslint-disable-line react-hooks/exhaustive-deps

    const galleria = useRef(null);

    const itemTemplate = (item) => {
        return <img src={item.itemImageSrc} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
    }

   

    const caption = (item) => {
        return (
            <React.Fragment>
                <div className="text-xl mb-2 font-bold">{item.title}</div>
            </React.Fragment>
        );
    }

    return (

        <>

          
            <Galleria ref={galleria} value={images}  style={{ maxWidth: '70%' }} caption={caption}
                fullScreen showItemNavigators showThumbnails={false} item={itemTemplate}  />
            <Modal
                basic
           
                open={open}
                size='small'
          
            >
               
                <ModalContent>
                {loading ? <div style={{ backgroundColor: "#f9fafb", borderRadius: 5 }}>{loadingShow()}</div> : ""}
                </ModalContent>
               
            </Modal>
        </>

    )
}

export default GooglePhotosViewer;