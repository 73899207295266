import { useContext } from "react";
import AuthContext from "../context/authContext";
import {SCezarencrypt} from "../myfunctions"


export default function useAuth(){
    
    const authContext = useContext(AuthContext);
    const auth=authContext.isAuthenticated;
    

    const setAuth = (isAuthenticated,tokenData = null,userInfo=null) => {
        if(isAuthenticated){
              
            if(tokenData){
                window.localStorage.setItem('token',tokenData);    
            } 
            if(userInfo){
              
                window.localStorage.setItem('userInfo',SCezarencrypt(userInfo));    
               
            } 
  
            authContext.login(); 
            
        }else{
            authContext.logout();
            authContext.changeToken(null);
            authContext.changeUser();
            window.sessionStorage.clear();
            window.localStorage.clear();
            
        }
        
        
    }
    return [auth,setAuth]
}