import React, { useContext, useState,useEffect } from 'react';
import {  Form, FormButton,  FormInput,  Input,  Segment } from 'semantic-ui-react';
import AuthContext from '../context/authContext';

import { useToasts } from 'react-toast-notifications';
import { ConnectWS } from '../ConnectWS';



function ChangeLimitMembers({membersLimit,subTournamentRow,tournament_id}) {
    const authContext = useContext(AuthContext);
    const { addToast } = useToasts();

  const [from, setFrom] = useState({ members_limit: 0});

  useEffect(() => {
    setFrom({members_limit:membersLimit})
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [membersLimit])// eslint-disable-line react-hooks/exhaustive-deps


  const submit = e => {
    e.preventDefault();
   


    //this.tournament_id = req.body.tournament_id
    let parent_subtournament_id = subTournamentRow.parent_subtournament_id
    let subtournament_id = subTournamentRow.subtournament_id
    let subtournament_type = subTournamentRow.type
    let member_type = subTournamentRow.member_type
    let subtournament_name = subTournamentRow.name
    let members_limit = Number(from.members_limit)
  
    /*

this.tournament_id = req.body.tournament_id
this.parent_subtournament_id = req.body.parent_subtournament_id
this.type = req.body.subtournament_type
this.member_type = req.body.member_type
this.name = req.body.subtournament_name
this.members_limit = req.body.members_limit

    */
    
    ConnectWS("/generator/editSubtournament", authContext.token, { tournament_id:tournament_id,subtournament_id:subtournament_id,parent_subtournament_id:parent_subtournament_id,subtournament_type:subtournament_type,member_type:member_type,subtournament_name:subtournament_name,members_limit:members_limit }, function (response, logout) {

      if (logout === 1) {
       

      } else if (response && response.status && Number(response.status) === 200) {
       
        addToast(response.data.message, { appearance: 'success' });
        authContext.changeRefreshView(authContext.refreshView + 1)

      } else {

        if(logout===101){
          addToast(response, { appearance: 'info' });
        }else if(logout===102){
          addToast(response, { appearance: 'warning' });
        }else{
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }



    });

  }


    return (
      <Segment style={{backgroundColor:"#f2f2f2"}}>
      <Form size='tiny' onSubmit={submit}>  
    
         <FormInput
              control={Input}
              value={from.members_limit}
              type='number'
              min="0"
              max="500"
              onChange={(event, data) => setFrom({ ...from, members_limit: data.value })}
              label='Limit uczestników dla zapisów online: 0 - brak limitu'
              placeholder='Limit uczestników dla zapisów online'
            />
            <FormButton  fluid size='mini'>Zapisz</FormButton>
        </Form>
        </Segment>
    )
}

export default ChangeLimitMembers;