import React, { useEffect } from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';



function ShowImgAvatar({ urlpic, mtype, msize, ShowImgAvatar = true }) {


    let w = msize === "massive" ? 60 : msize === "large" ? 100 : msize === "mini" ? 25 : 30
    let h = msize === "massive" ? 60 : msize === "large" ? 100 : msize === "mini" ? 25 : 30

    let mUrl = ""
    try {
        if (urlpic && urlpic.indexOf('/img/member/getImage/') > -1) {
            //mtype - 1 user 2 team
            mUrl = urlpic ? urlpic : mtype === "TEAM" ? process.env.REACT_APP_PUBLIC_URL + '/img/noteam.png' : process.env.REACT_APP_PUBLIC_URL + '/img/nouser.png'
        }
        else {
            //mtype - 1 user 2 team
            mUrl = urlpic ? "https://api.turniej-generator.pl/img/member/getImage/"+ urlpic : mtype === "TEAM" ? process.env.REACT_APP_PUBLIC_URL + '/img/noteam.png' : process.env.REACT_APP_PUBLIC_URL + '/img/nouser.png'
        }
    } catch (e) {
        mUrl = urlpic ? urlpic : mtype === "TEAM" ? process.env.REACT_APP_PUBLIC_URL + '/img/noteam.png' : process.env.REACT_APP_PUBLIC_URL + '/img/nouser.png'
    }
    useEffect(() => {

        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [ShowImgAvatar])// eslint-disable-line react-hooks/exhaustive-deps


    return (
        ShowImgAvatar ?  <LazyLoadImage
        alt="zdjęcie profilowe"
        height={h}
        src={mUrl} // use normal <img> attributes as props
        width={w}
        effect="blur" className={'imgProfilTG hiddenPrint'} />
       : ""
      //  ShowImgAvatar ? <img className={urlpic ? '' : 'imgProfilTG'} src={mUrl} width={w} height={h} alt="zdjęcie profilowe" /> : ""
    )
}

export default ShowImgAvatar;