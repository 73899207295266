import React, { useState, useEffect, useContext } from 'react';
import { Icon, Header, Image, Grid,  Button, Form,  Modal } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';
import { ConnectWS } from '../../../../ConnectWS';
import useAuth from '../../../../hooks/useAuth';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { useToasts } from 'react-toast-notifications';
import LogoTeamLigaGenerator from '../../../../assets/logotgver1.svg';


import { changeFormatDateTimeDB } from '../../../../myfunctions';
import InputCalendarAndTime from '../../../../tools/InputCalendarAndTime';


function CloneActivities({ setcloneActivitiesOpenModal, cloneActivitiesOpenModal }) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
  const [loading, setloading] = useState(false);
  const [form, setForm] = useState({
    source_activity_id: 0,
    start_date: '',
    stop_date: '',
    register_stop_date: '',
  })


  function plusSevenDay(date_value){
    let date = new Date(date_value);
    date.setDate(date.getDate() + 7);
    return date
  }
  useEffect(() => {


    if (cloneActivitiesOpenModal) {
      console.log(cloneActivitiesOpenModal)
      setForm({
        ...form,
        source_activity_id: cloneActivitiesOpenModal.activity_id,
        start_date: plusSevenDay(cloneActivitiesOpenModal.activity_start_date),
        stop_date: plusSevenDay(cloneActivitiesOpenModal.activity_stop_date),
        register_stop_date: plusSevenDay(cloneActivitiesOpenModal.register_stop_date)
      })
    }

    return () => {


    }; // use effect cleanup to set flag false, if unmounted
  }, [cloneActivitiesOpenModal])// eslint-disable-line react-hooks/exhaustive-deps


  const submitCloneTournament = async e => {
    e.preventDefault();


    setloading(true)
    ConnectWS("/activity/copyActivity", authContext.token, {
      source_activity_id: form.source_activity_id,
      activity_start_date: changeFormatDateTimeDB(form.start_date),
      activity_stop_date: changeFormatDateTimeDB(form.stop_date),
      register_stop_date: changeFormatDateTimeDB(form.register_stop_date),
    }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {

        addToast(response.data.message, { appearance: 'success' });
        setcloneActivitiesOpenModal(null)
        authContext.changeRefreshView(authContext.refreshView + 1)

      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }
      setloading(false)



    });

  }


  return (
    <Modal
      open={cloneActivitiesOpenModal ? true : false}
      size={'large'}


    >
      <Modal.Header>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={12} verticalAlign="middle">
              <Header as='h4' textAlign='center'>
                <Icon name='clone' />
                <Header.Content>Kopiowanie treningów</Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right" only='large screen'>
              <Image alt="logo" floated='right' size='big' centered src={LogoTeamLigaGenerator} />
            </Grid.Column>
          </Grid.Row>
        </Grid>

      </Modal.Header>
      <Modal.Content>

        <Modal.Description>
          <Form onSubmit={submitCloneTournament} size='mini' >
           
            <Form.Field >
              <label>Początek zajęć</label>
              <InputCalendarAndTime form={form} setForm={setForm} inputValue={"start_date"} />  
            </Form.Field>
            <Form.Field >
              <label>Koniec zajęć</label>
              <InputCalendarAndTime form={form} setForm={setForm} inputValue={"stop_date"} />  
            </Form.Field>

            <Form.Field >
              <label>Koniec Zapisów</label>
              <InputCalendarAndTime form={form} setForm={setForm} inputValue={"register_stop_date"} />  
            </Form.Field>
           
           

            <Button loading={loading} disabled={loading} fluid>UTWÓRZ </Button>

          </Form>

        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => setcloneActivitiesOpenModal(null)}>
          ZAMKNIJ
        </Button>

      </Modal.Actions>
    </Modal>

  )



}

export default CloneActivities;