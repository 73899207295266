import React, { useEffect, useState, useContext } from 'react';
import { Grid, Header, Icon, Image, Message, Modal, Table } from 'semantic-ui-react';
import LogoTeamLigaGenerator from '../assets/logotgver1.svg';
import "cropperjs/dist/cropper.css";
import { ConnectWS } from '../ConnectWS';
import AuthContext from '../context/authContext';
import { useToasts } from 'react-toast-notifications';
import { Accordion, AccordionTab } from 'primereact/accordion';

function AddMembersToTournament({ openModalAddMembersToTournament, setopenModalAddMembersToTournament }) {
    const { addToast } = useToasts();

    const authContext = useContext(AuthContext);
    const [rowData, setrowData] = useState([]);

    const [mdata, setmdata] = useState(null);

    useEffect(() => {
        if (openModalAddMembersToTournament && openModalAddMembersToTournament.open) {
            getOrganizerLastTournamentsFinalTables(openModalAddMembersToTournament.data.subtournament_id)
            if (openModalAddMembersToTournament.data) {
                setmdata(openModalAddMembersToTournament.data)

            }
        }

        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [openModalAddMembersToTournament.open])// eslint-disable-line react-hooks/exhaustive-deps


    function getOrganizerLastTournamentsFinalTables(subtournament_id) {
        ConnectWS("generator/getOrganizerLastTournamentsFinalTables", authContext.token, { subtournament_id: subtournament_id }, function (response, logout) {

            if (logout === 1) {

            } else if (response && response.status && Number(response.status) === 200) {
                 setrowData(response.data)
            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
            }



        });
    }

    function closeModalF() {
        setopenModalAddMembersToTournament({ open: false, data: null })
    }

    function addMemberToTournament(member_id, subtournament_id) {
        ConnectWS("generator/addMemberToTournament", authContext.token, { subtournament_id: subtournament_id, member_id: member_id }, function (response, logout) {
            if (logout === 1) {

            } else if (response && response.status && Number(response.status) === 200) {
                addToast(response.data.message, { appearance: 'success' });
                getOrganizerLastTournamentsFinalTables(subtournament_id)
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
            }
        });
    }

    function delMemberToTournament(member_id, subtournament_id) {
        ConnectWS("generator/removeMemberFromTournament", authContext.token, { subtournament_id: subtournament_id, member_id: member_id }, function (response, logout) {
            if (logout === 1) {

            } else if (response && response.status && Number(response.status) === 200) {
                addToast(response.data.message, { appearance: 'success' });
                getOrganizerLastTournamentsFinalTables(subtournament_id)
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
            }
        });
    }

    return (
        <>
            <Modal
                onClose={() => closeModalF()}
                open={openModalAddMembersToTournament && openModalAddMembersToTournament.open ? openModalAddMembersToTournament.open : false}
                size='large'
            >
                <Modal.Header>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={12} verticalAlign="middle">
                                <Header as='h2' textAlign='center'>
                                    <Icon name='add' color="orange" />
                                    <Header.Content>Dodaj uczestników</Header.Content>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign="right" only='large screen'>
                                <Image floated='right' size='big' alt="logo" centered src={LogoTeamLigaGenerator} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Header>
                <Modal.Content scrolling>

                    <Modal.Description>
                      
                            {rowData && rowData.length > 0 ?
                              <Accordion>
                               { rowData.map((row, i) =>
                                    <AccordionTab header={
                                        <div>{row.tournament_name} - {row.start_date}</div>
                                    }>
                                        <p className="m-0">
                                            {row.subtournaments && row.subtournaments.length > 0 ?
                                                <Accordion>
                                                    {row.subtournaments.map((row1, j) =>
                                                        <AccordionTab header={
                                                            <div>Kategoria - {row1.subtournament_name}</div>
                                                        }>
                                                            Możesz dodać dowolnego uczestnika do kategorii <b>{mdata ? mdata.name : "?"}</b> w rozgrywkach <b>{mdata ? mdata.tournament_name : "?"}</b> wystarczy kliknąć <Icon name="add" color='orange' />
                                                            <div style={{ overflowX: "auto", marginTop: 5, width: "100%" }} >
                                                                <Table size='small' inverted={authContext.darkMode} celled striped compact unstackable >
                                                                    <Table.Header>
                                                                        <Table.Row>
                                                                            <Table.HeaderCell width={1} textAlign='center' singleLine>DODAJ</Table.HeaderCell>
                                                                            <Table.HeaderCell width={1} textAlign='center' singleLine>MIEJSCE</Table.HeaderCell>
                                                                            <Table.HeaderCell textAlign='center' singleLine>UCZESTNIK</Table.HeaderCell>
                                                                        </Table.Row>
                                                                    </Table.Header>
                                                                    <Table.Body>
                                                                        {row1.final_table && row1.final_table.length > 0 ? row1.final_table.map((row2, j) =>
                                                                            <Table.Row key={j} className={j < row.league_winners_count ? 'winnerScoreP' : j >= row1.final_table.length - row.league_losers_count ? 'loserScoreP' : ''}>
                                                                                <Table.Cell textAlign='center'>
                                                                                    {row2.subtournament_member_exists === 1 ? <Icon name="trash" className='cursorPointer' onClick={() => delMemberToTournament(row2.member_id, mdata.subtournament_id)} /> : <Icon name="add" color='orange' className='cursorPointer' onClick={() => addMemberToTournament(row2.member_id, mdata.subtournament_id)} />}


                                                                                </Table.Cell>
                                                                                <Table.Cell textAlign='center'>{row2.order_nr_range}</Table.Cell>
                                                                                <Table.Cell textAlign='center'>{row2.member_name}</Table.Cell>
                                                                            </Table.Row>
                                                                        ) : ""}
                                                                    </Table.Body>
                                                                </Table>
                                                            </div>
                                                        </AccordionTab>
                                                    )}


                                                </Accordion>
                                                : ""}

                                        </p>
                                    </AccordionTab>
                               )}
                                </Accordion>
                                  : <Message>Lista turniejów jest pusta</Message>}
                        
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>

                </Modal.Actions>
            </Modal>


        </>
    )
}

export default AddMembersToTournament;