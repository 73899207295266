import React, { useEffect, useState } from 'react';
import CountDownTimer from '../layout/ui/countDownTimer/CountDownTimer';
import moment from 'moment';
import { Icon, Message } from 'semantic-ui-react';

function TimerTournamentDemo(props) {

    const [createDateTaddtimeoneh, setcreateDateTaddtimeoneh] = useState(null)

    useEffect(() => {

        if (props.tournament_create_time) {
            try {
                setcreateDateTaddtimeoneh(moment(props.tournament_create_time).add(1, 'hour').format('YYYY-MM-DD HH:mm:ss'));
            } catch {
                setcreateDateTaddtimeoneh(props.tournament_create_time)
            }
        }
        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [props.tournament_create_time])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {createDateTaddtimeoneh ?
                <Message icon info textAlign='center'>
                    <Icon name='info circle' />
                    <Message.Content>
                        <Message.Header>Jesteś na koncie testowym</Message.Header>
                        Turniej zostanie skasowany za
                    </Message.Content>
                    <br />
                    <div>
                        <CountDownTimer endDate={createDateTaddtimeoneh} textValue="" textValueFinish="" beautifulText="0" />
                    </div>

                </Message>
                : ""}
        </>
    )
}

export default TimerTournamentDemo;