import React, { useState, useEffect, useContext } from 'react';
import { Icon, Header, Message, Input, Divider, Step, Segment, Label, StepGroup } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';
import { useToasts } from 'react-toast-notifications';
import useAuth from '../../../../hooks/useAuth';



function MyReferrals(props) {
  const { addToast } = useToasts();// eslint-disable-line no-unused-vars
  const authContext = useContext(AuthContext);// eslint-disable-line no-unused-vars
  const [loading, setloading] = useState(false);// eslint-disable-line no-unused-vars
  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars

  const [formError, setFormError] = useState(false);// eslint-disable-line no-unused-vars

  const [myRefToken, setmyRefToken] = useState("MpdJaAXAG7hnqErsdHPKasdasdasdasd");// eslint-disable-line no-unused-vars
  const [myRefTokenCopied, setmyRefTokenCopied] = useState(null);

  useEffect(() => {
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log("View: MyReferrals")
    }
    setmyRefTokenCopied(null)
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps


  function copyRefToken(valRefToken) {
    navigator.clipboard.writeText(valRefToken)
    setmyRefTokenCopied(valRefToken)
  }


  return (
    <>
    <Segment placeholder textAlign='center'>

        <Header as='h2' >
          <Icon.Group size='big'>
            <Icon name='handshake' />
            <Icon corner name='dollar' />
          </Icon.Group>
          <br />
          Kochasz zarabiać?
        </Header>
        <b> Opis Funkcji Poleć Innym Organizatorom w Aplikacji Turniej Generator</b><br />
        <div style={{ fontSize: 15, lineHeight: "16px" }}>Aplikacja Turniej Generator wprowadza innowacyjną funkcję umożliwiającą użytkownikom polecanie platformy innym organizatorom turniejów.<br />
          Kluczowym elementem tej funkcji jest unikalny REFTOKEN, który służy do śledzenia poleceń i nagradzania użytkowników za ich aktywność.</div>
        <div style={{ overflowX: "auto", width: "100%" }} >
          <Message info={myRefTokenCopied ? false : true} success={myRefTokenCopied ? true : false} style={{ width: 800, margin: "0 auto", marginTop: 30, marginBottom: 30 }}>
            <Input style={{ width: 700, margin: "0 auto" }} label='REFTOKEN' value={myRefToken} action={{
              color: myRefTokenCopied ? 'green' : 'blue',
              labelPosition: 'right',
              icon: 'copy',
              content: myRefTokenCopied ? 'SKOPIOWANY' : 'KOPIUJ REFTOKEN',
              onClick: () => copyRefToken(myRefToken)
            }} />
             {myRefTokenCopied ? <div style={{marginTop:10,fontSize:13}}> REFTOKEN został skopiowany do schowka, przekaż go teraz innym organizatorom</div> :""}
          </Message>
         

          <div style={{ fontSize: 14, lineHeight: "16px", textAlign: "left" }}>
            <StepGroup ordered fluid vertical style={{ width: 800, margin: "0 auto" }}>
              <Step >
                <Step.Content style={{ fontSize: 14, lineHeight: "16px", textAlign: "left" }}>
                  <Step.Title>Poleć Innym Organizatorom</Step.Title>
                  <Step.Description>Użytkownik może polecać aplikację innym organizatorom, udostępniając im swój REFTOKEN.</Step.Description>
                </Step.Content>
              </Step>

              <Step>
                <Step.Content style={{ fontSize: 14, lineHeight: "16px", textAlign: "left" }}>
                  <Step.Title>Korzyści dla Osoby Polecającej</Step.Title>
                  <Step.Description>Jeśli organizator polecony zakupi TOKENY z wpisanym REFTOKEN-EM,<br /> osoba polecająca otrzymuje XX.</Step.Description>
                </Step.Content>
              </Step>
              <Step>
                <Step.Content style={{ fontSize: 14, lineHeight: "16px", textAlign: "left" }}>
                  <Step.Title>Śledź Efektywność Poleceń</Step.Title>
                  <Step.Description>Aplikacja śledzi skuteczność poleceń, umożliwiając osobie polecającej monitorowanie,<br /> ile organizatorów zostało zaproszonych i ile z nich zaczęło korzystać z platformy.</Step.Description>
                </Step.Content>
              </Step>
              <Step>
                <Step.Content style={{ fontSize: 14, lineHeight: "16px", textAlign: "left" }}>
                  <Step.Title>Wspieraj Rozwój Społeczności</Step.Title>
                  <Step.Description>Ta funkcja wspiera dynamiczny rozwój społeczności organizatorów turniejów,<br /> umożliwiając im wymianę doświadczeń i promowanie platformy w szerszym gronie.</Step.Description>
                </Step.Content>
              </Step>
            </StepGroup>

          </div>
        </div>

      </Segment>
      <Divider />
      <Segment >
        <Label color='red' ribbon>
          Monitor poleceń
        </Label>
        <Header as='h3' block>
          BRAK POLECEŃ
        </Header>
      </Segment>

    </>
  )
}

export default MyReferrals;