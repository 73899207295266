import React, { useEffect, useState, useContext } from 'react';
import {  Button } from 'semantic-ui-react'
import { useToasts } from 'react-toast-notifications';
import { ConnectWS } from '../../../../ConnectWS';
import AuthContext from '../../../../context/authContext';

function BuySubscription(props) {
  const [form, setForm] = useState(null);
  const authContext = useContext(AuthContext);
  const { addToast } = useToasts();

  function generateHotPayDataSubscription(stype) {
    let amount = props.price;
    let subscription_type = props.subscription_type

    ConnectWS("/payment/generateHotPayDataSubscription", authContext.token, { amount: amount, subscription_type:subscription_type }, function (response, logout) {

      if (logout === 1) {
        addToast("Brak zalogowanego użytkownika", { appearance: 'info' });
        setForm(null)
      } else if (response && response.status && Number(response.status) === 200) {
        //setRowDataTournament(response.data)
        setForm(response.data)
        document.getElementById("orderSubscriptionBuy").submit();

      } else {
        if(logout===101){
          addToast(response, { appearance: 'info' });
        }else if(logout===102){
          addToast(response, { appearance: 'warning' });
        }else{
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
        setForm(null)
      }

    });

  }

  useEffect(() => {
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [props.isDemo,props.btnname],props.issmall)// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Button fluid color='violet' size={props.issmall ? "tiny" : "medium"} disabled={false} onClick={() => props.isDemo ? addToast('Zablokowane na koncie testowym', { appearance: 'warning' }) : generateHotPayDataSubscription(props.stype)}>
        {props.btnname}
      </Button>
      {form ?
        <form style={{ display: "none" }}
          id="orderSubscriptionBuy"
          action="https://platnosc.hotpay.pl/"
          method="post">
          <input required name="SEKRET" value={form.SEKRET} type="hidden" />
          <input required name="HASH" value={form.HASH} type="hidden" />
          <input required name="KWOTA" value={form.KWOTA} type="hidden" />
          <input required name="NAZWA_USLUGI" value={form.NAZWA_USLUGI} type="hidden" />
          <input required name="ADRES_WWW" value={form.ADRES_WWW} type="hidden" />
          <input required name="ID_ZAMOWIENIA" value={form.ID_ZAMOWIENIA} type="hidden" />
          <input name="EMAIL" value={form.EMAIL} type="hidden" />
          <input name="DANE_OSOBOWE" value={form.DANE_OSOBOWE} type="hidden" />
          <button type="submit">Submit</button>
        </form>
        : ""}
    </>

  )
}

export default BuySubscription;