import React, { useEffect, useState, useRef } from 'react';
import { Header, Icon, Container, Button, Modal, Grid, Image } from 'semantic-ui-react'
import LogoTeamLigaGenerator from '../../../../assets/logotgver1.svg';
import tglh from '../../../../assets/tglh.png';
//import { QRCodeCanvas } from "qrcode.react";
import { QRCode } from 'react-qrcode-logo';

function GenQrCode(props) {
  const [open, setOpen] = useState(false)
  const qrRef = useRef();

  function downloadQRCode() {

    let canvas = qrRef.current.querySelector("canvas");
    let image = canvas.toDataURL("image/png");
    let anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = props.name ? props.name + `.png` : "";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

  };

  useEffect(() => {

    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  return (

    <>
      <span className='cursorPointer' style={{ color: "brown" }} onClick={() => setOpen(true)}><b><Icon name="qrcode" />Pobierz QrCode</b></span>
      <Modal
        dimmer='blurring'
        closeIcon
        centered={true}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        <Modal.Header>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={12} verticalAlign="middle">
                <Header as='h2' textAlign='center'>
                  <Icon name='qrcode' color="red" />
                  <Header.Content>QrCode</Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right" only='large screen'>
                <Image floated='right' size='big' alt="logo" centered src={LogoTeamLigaGenerator} />
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </Modal.Header>
        <Modal.Content>
          <Container textAlign='center'>
            <Grid centered verticalAlign='middle'>
              <Grid.Row columns={2} textAlign='center'>
              <Grid.Column>
                <Image src={process.env.REACT_APP_PUBLIC_URL + '/img/qrcode.gif'}  alt="qrcode"/>
              </Grid.Column>
              <Grid.Column textAlign='center'>
                <b> Nazwa:</b> {props.name ? props.name : ""}<br />
                <b>Link:</b> <a href={props.url ? props.url : ""} target="_blank" rel="noopener noreferrer">{props.url ? props.url : ""}</a><br /><br />
                <div ref={qrRef}>
                <QRCode value={props.url ? props.url : ""} logoImage={tglh}  ecLevel={"H"}/>
                </div>
                <br />
                <Button onClick={() => downloadQRCode()}>
                  POBIERZ QRCODE
                </Button>
                <br /><br />
              </Grid.Column>
             
              </Grid.Row>
            </Grid>

{/*<QRCodeCanvas
                  id="qrCode"
                  value={props.url ? props.url : ""}
                  size={300}
                  level={"H"}
                /> */}


            Wygenerowany QrCode możesz umiescic np. na plakacie, po zeskanowaniu go aparatem ze smartfona, osoba zostanie przekierowana na strone rozgrywek.
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)}>
            ZAMKNIJ
          </Button>

        </Modal.Actions>
      </Modal>
    </>
  )
}

export default GenQrCode;