import { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/authContext';

export default function useCheckUserIsDemo() {
  const authContext = useContext(AuthContext);
  const [checkUserIsDemo, setcheckUserIsDemo] = useState(false);

  useEffect(() => {
    if (authContext.user && authContext.user.userId && authContext.user.userId===1 ? true : false) {
      setcheckUserIsDemo(true)
    }else{
      setcheckUserIsDemo(false)
    }
  }, [authContext]);// eslint-disable-line react-hooks/exhaustive-deps

  return checkUserIsDemo;
}