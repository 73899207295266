import React, { useEffect,useContext,useState } from 'react';
import { Button,  Form, Input, Segment } from 'semantic-ui-react';
import AuthContext from '../context/authContext';


import { ConnectWS } from '../ConnectWS';
import { useToasts } from 'react-toast-notifications';


function EditRowFinalTable({ myEditRow }) {
    const authContext = useContext(AuthContext);

    const { addToast } = useToasts();
    const [loading, setloading] = useState(false)
    const [form, setForm] = useState({ id:0,
        subtournament_id:0,
        order_nr_range:0,
        matches_played:0,
        wins:0,
        draws:0,
        loses:0,
        total_points_winners:0,
        total_points_losers:0,
        subtournament_point:0,
    })
    useEffect(() => {
       
       if(myEditRow){
        setForm({...myEditRow})
       }
        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [myEditRow])// eslint-disable-line react-hooks/exhaustive-deps

    const accRowEdit = async e => {
        e.preventDefault();
       
        setloading(true)
        ConnectWS("/generator/updateTabPktRow", authContext.token, {...form}, function (response, logout) {
            if (logout === 1) {
            } else if (response && response.status && Number(response.status) === 200) {
      
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
              if(logout===101){
                addToast(response, { appearance: 'info' });
              }else if(logout===102){
                addToast(response, { appearance: 'warning' });
              }else{
                addToast(response, { appearance: 'error' });
              }
            }
            setloading(false)
          });
    }
  

    return (
        <Segment style={{backgroundColor:"#f7f7f7"}}>
        <Form size='tiny' onSubmit={accRowEdit} style={{marginBottom:10}}>
               <Form.Field
            control={Input}
           
            value={form.order_nr_range}
            onChange={(event, data) => setForm({ ...form, order_nr_range: (((data.value))) })}
            label='Miejsce'
            placeholder='Miejsce'
            required={true}
          />
            <Form.Field
            control={Input}
            type='number'
            value={form.subtournament_point}
            onChange={(event, data) => setForm({ ...form, subtournament_point: (((data.value))) })}
            label='Punktacja z turnieju'
            placeholder='Punktacja z turnieju'
            required={true}
          />
        
           <Form.Field
            control={Input}
            type='number'
            value={form.matches_played}
            onChange={(event, data) => setForm({ ...form, matches_played: (((data.value))) })}
            label='Pojedynki'
            placeholder='Pojedynki'
            required={true}
          />
           <Form.Field
            control={Input}
            type='number'
            value={form.wins}
            onChange={(event, data) => setForm({ ...form, wins: (((data.value))) })}
            label='Wygrane'
            placeholder='Wygrane'
            required={true}
          />
          <Form.Field
            control={Input}
            type='number'
            value={form.draws}
            onChange={(event, data) => setForm({ ...form, draws: (((data.value))) })}
            label='Remisy'
            placeholder='Remisy'
            required={true}
          />
           <Form.Field
            control={Input}
            type='number'
            value={form.loses}
            onChange={(event, data) => setForm({ ...form, loses: (((data.value))) })}
            label='Przegrane'
            placeholder='Przegrane'
            required={true}
          />
           <Form.Field
            control={Input}
            type='number'
            value={form.total_points_winners}
            onChange={(event, data) => setForm({ ...form, total_points_winners: (((data.value))) })}
            label='BILANS - Wygrane małe PKT'
            placeholder='Wygrane małe PKT'
            required={true}
          />
          <Form.Field
            control={Input}
            type='number'
            value={form.total_points_losers}
            onChange={(event, data) => setForm({ ...form, total_points_losers: (((data.value))) })}
            label='BILANS - Przegrane małe PKT'
            placeholder='Przegrane małe PKT'
            required={true}
          />
          <Button color='orange' style={{ marginTop: 17 }} size='tiny' loading={loading} disabled={loading} type='submit' fluid>ZAPISZ DANE</Button>
        </Form>
        </Segment>
    )
}

export default EditRowFinalTable;