import axios from 'axios'


const instance = axios.create(
    {
        baseURL: process.env.REACT_APP_API,
        
    }
);

instance.defaults.timeout = 60000;

export default instance;