import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Button, Container, Icon, Modal } from 'semantic-ui-react';

function ShowFirstLoginPage() {
    const [open, setOpen] = useState(false)
    const [cookies, setCookie] = useCookies(['TGCookieSFL']);



    useEffect(() => {

        checkIsCookie()

        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    function closeModalTG() {
        try{
            let expiresdate = new Date();
            let today = new Date();
            expiresdate.setDate(today.getDate() + 300);
            setCookie('TGCookieSFL', 1, { path: '/', expires: expiresdate });
        }catch(e){

        }
        setOpen(false)
    }

    function checkIsCookie() {
        if (cookies.TGCookieSFL) {
        } else {

            setOpen(true)
        }
    }


    return (

        <Modal
            basic

            onOpen={() => setOpen(true)}
            open={open}
            size='small'

        >
            <Modal.Content >
                <Container textAlign='center' style={{ fontSize: 20, backgroundColor: "#242323", padding: 10, borderRadius: 5 }}>


                    Kliknij ikonę <Icon name='bars' /> w prawym górnym rogu, aby rozwinąć menu organizatora

                </Container>
            </Modal.Content>
            <Modal.Actions>
                <Button basic color='green' inverted onClick={() => closeModalTG() }>
                    <Icon name='checkmark' /> ROZUMIEM
                </Button>

            </Modal.Actions>
        </Modal>
    )
}

export default ShowFirstLoginPage;